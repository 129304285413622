import React from "react";
import { graphql, Link } from "gatsby";
import ProductsTemplate from "../templates/ProductsTemplate";


export default ({ data: { allProducts } }) => {
	const productsByLocale = allProducts.edges.filter(
		(item) => item.node.locale.locale === "fi"
    );
	return (
		<ProductsTemplate allProducts={productsByLocale} locale="fi" />
	);
};

export const query = graphql`
	query {
		allProducts: allWpProduct {
			edges {
				node {
					slug
					locale {
						locale
					}
					productData {
						productName
						productNameHyphenated
						productSubtitle
						productImage {
							localFile {
								childImageSharp { 
									gatsbyImageData(height: 430)
								}
							}
						}
						productMainCategory
						productSubCategory
					}
				}
			}
		}
	}
`;
